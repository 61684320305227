export const IMAGE_TYPE = {
  projects: '400x294',
  project_main: '824x560',
  project_secondary: '416x276',
  projects_map: '240x160',
  projects_mobile: '280x294',

  availabilities: '294x358/contain',
  availability_main: '824x745/contain',
  availability_mobile: '240x280/contain',
  availabilities_mobile: '400x280/contain',
  project_main_mobile: '824x560/contain',

  exclusive_banner: '1248x640',

  slide_preview: '80x80',
} as const;
export type ImageType = typeof IMAGE_TYPE[keyof typeof IMAGE_TYPE];

export function replaceImgSrc(url: string, type: ImageType): string {
  if (url.includes('no-image')) {
    return url;
  }
  const urlParse = new URL(url);
  return `${urlParse.origin}/${type}${urlParse.pathname}`;
}
